<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div
      class="view-wrapper"
      data-naver-offset="150"
      data-menu-item="#home-sidebar-menu"
      data-mobile-item="#home-sidebar-menu-mobile"
    >
      <div class="lifestyle-dashboard-bg"></div>

      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <div class="title-wrap">
              <h1 class="title is-4">Data Pos</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Lifestyle Dashboard V1-->
            <div class="lifestyle-dashboard lifestyle-dashboard-v1">
              <div class="dashboard-header-wrapper">
                <div class="dashboard-header">
                  <div class="avatar-container">
                    <img :src="getImgUrl" @error="onImageLoadFailure($event)" />
                  </div>
                  <div class="header-meta">
                    <div class="username-wrap">
                      <div class="username">
                        <h3>
                          <span>{{ kadaster.namaPos }}</span>
                        </h3>
                        <span>{{ kadaster.lokasiPos }} </span>
                        <span> - </span>
                        <span>{{
                          kadaster.sungai ? kadaster.sungai.namaSungai : "-"
                        }}</span>
                      </div>
                      <div class="badges">
                        <span class="tag is-rounded is-success">{{
                          kadaster.kodeRegister
                        }}</span>
                      </div>
                    </div>
                    <div class="meta-stats">
                      <div class="meta-stat">
                        <span>Latitude :</span>
                        <span> {{ kadaster.lat }}</span>
                      </div>
                      <div class="meta-stat">
                        <span>Longitude :</span>
                        <span> {{ kadaster.lng }}</span>
                      </div>
                      <div class="meta-stat">
                        <span>Elevasi :</span>
                        <span> {{ kadaster.elevasi }}</span>
                      </div>
                    </div>

                    <!-- <div class="meta-achievements">
                      <ModalUpdateKadaster :registerId="registerId" />
                    </div> -->
                    <div class="meta-achievements">
                      <div class="buttons">
                        <router-link
                          class="button h-button is-info is-outlined"
                          outlined
                          :to="`/dashboard/pos/update/${kadaster.registerId}`"
                        >
                          Rubah
                        </router-link>
                        <button
                          class="button h-button is-danger is-outlined"
                          @click.prevent="deletKadaster"
                          type="is-success"
                          outlined
                        >
                          Hapus
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="end">
                    <!--Dropdown-->
                  </div>
                </div>
              </div>

              <div class="tabs-wrapper">
                <div class="tabs-inner">
                  <div class="tabs">
                    <ul>
                      <li
                        v-bind:class="{ 'is-active': isActive == 'Detail Pos' }"
                      >
                        <a v-on:click="isActive = 'Detail Pos'">Detail Pos</a>
                      </li>
                      <li
                        v-bind:class="{
                          'is-active': isActive == 'HidrologiPda',
                        }"
                        v-if="kadaster.isManualPda"
                      >
                        <a v-on:click="isActive = 'HidrologiPda'"
                          >Duga Air Manual</a
                        >
                      </li>
                      <li
                        v-bind:class="{ 'is-active': isActive == 'PDA' }"
                        v-if="kadaster.isTelemetryPda"
                      >
                        <a v-on:click="isActive = 'PDA'">Duga Air Otomatis</a>
                      </li>
                      <li
                        v-bind:class="{
                          'is-active': isActive == 'HidrologiPch',
                        }"
                        v-if="kadaster.isManualPch"
                      >
                        <a v-on:click="isActive = 'HidrologiPch'"
                          >Curah Hujan Manual</a
                        >
                      </li>
                      <li
                        v-bind:class="{ 'is-active': isActive == 'PCH' }"
                        v-if="kadaster.isTelemetryPch"
                      >
                        <a v-on:click="isActive = 'PCH'"
                          >Curah Hujan Otomatis</a
                        >
                      </li>
                      <li
                        v-bind:class="{ 'is-active': isActive == 'KLM' }"
                        v-if="kadaster.jenisDevice == 'KLM'"
                      >
                        <a v-on:click="isActive = 'KLM'">Klimatologi</a>
                      </li>

                      <li
                        v-bind:class="{ 'is-active': isActive == 'Debit' }"
                        v-if="kadaster.isTelemetryPda"
                      >
                        <a v-on:click="isActive = 'Debit'">Debit</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="tab-content"
                  v-bind:class="{ 'is-active': isActive == 'Detail Pos' }"
                >
                  <div class="lifestyle-dashboard lifestyle-dashboard-v4">
                    <!--Header-->
                    <div class="column is-12">
                      <div class="illustration-header-2">
                        <img
                          src="../../assets/img/illustrations/plants/1.svg"
                          height="90"
                          width="100"
                          alt=""
                        />
                        <div
                          v-if="kadaster.jenisDevice != 'KLM'"
                          class="header-meta"
                        >
                          <h3>Data Detail Pos.</h3>
                          <p>
                            Data diinput oleh Administrator untuk menentukan
                            Level Siaga, rumus debit dan batch file data
                            hidrologi manual.
                          </p>
                        </div>
                        <div v-else class="header-meta">
                          <h3>Data Detail Pos.</h3>
                          <p>
                            Data diinput oleh Administrator untuk menentukan
                            data Klimatologi.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span v-if="kadaster.isTelemetryPda">
                    <div class="body-title">
                      <h3>Tabel Level Status Sungai</h3>
                    </div>
                    <LevelList :regId="registerId" />

                    <div class="body-title" v-if="kadaster.isTelemetryPda">
                      <h3>Tabel Rumus Debit</h3>
                    </div>
                    <RumusDebit :registerId="registerId" />
                    <br />
                  </span>
                  <span v-if="kadaster.isManualPda || kadaster.isManualPch">
                    <div class="body-title">
                      <h3>Tabel File Excel Hidrologi Manual</h3>
                    </div>
                    <b-field>
                      <div class="grup">
                        <label> Unduh Format Template File Excel </label>
                        <div class="buttons">
                          <b-button
                            class="button1 is-success is-focused"
                            type="is-link"
                            outlined
                            v-if="kadaster.isManualPda"
                            ><a href="/files/POS_TEMPLATE_PDA.xlsx" download>
                              <span class="title1"> Unduh File PDA</span>
                            </a>
                          </b-button>
                          <b-button
                            class="button1 is-success is-focused"
                            type="is-link"
                            outlined
                            v-else
                            ><a href="/files/POS_TEMPLATE_PCH.xlsx" download>
                              <span class="title1"> Unduh File PCH</span>
                            </a>
                          </b-button>
                        </div>
                      </div>
                    </b-field>
                    <Tablebatch :registerId="registerId" />
                  </span>
                  <!-- <div v-if="kadaster.jenisDevice === 'KLM'">
                       <TableKlimatologi :regId="registerId" />
                  </div> -->

                  <!-- tabel volume klim evaporation -->
                  <div v-if="kadaster.jenisDevice === 'KLM'">
                    <div class="body-title">
                      <h3>Tabel Bak Volume Evaporasi</h3>
                    </div>
                    <div>
                      <TableEvaporation :regId="registerId" />
                    </div>
                  </div>
                  <!-- End tabel volume klim evaporation -->
                </div>
                <!-- END Tab -->

                <div
                  class="tab-content"
                  v-bind:class="{ 'is-active': isActive == 'PDA' }"
                  v-if="kadaster.isTelemetryPda"
                >
                  <TablePdaTelemetry :regId="registerId" />
                </div>
                <!-- END Tab -->

                <div
                  class="tab-content"
                  v-bind:class="{ 'is-active': isActive == 'PCH' }"
                  v-if="kadaster.isTelemetryPch"
                >
                  <TablePchTelemetry :regId="registerId" />
                </div>
                <!-- END TAB PCH -->

                <div
                  class="tab-content"
                  v-bind:class="{ 'is-active': isActive == 'KLM' }"
                  v-if="kadaster.jenisDevice"
                >
                  <TableKlimatologi :regId="registerId" />
                </div>

                <!-- END TAB klm -->

                <div
                  class="tab-content"
                  v-bind:class="{ 'is-active': isActive == 'HidrologiPda' }"
                  v-if="kadaster.isManualPda"
                >
                  <div class="datatable-toolbar">
                    <div class="buttons">
                      <SearchHidrologiPda :registerId="registerId" />
                    </div>
                  </div>
                  <div class="lifestyle-dashboard lifestyle-dashboard-v4">
                    <!--Header-->
                    <div class="column is-12">
                      <div class="illustration-header-2">
                        <div class="header-image">
                          <img
                            src="../../assets/img/illustrations/placeholders/search-1-dark.svg"
                            alt=""
                            width="200"
                          />
                        </div>
                        <div class="header-meta">
                          <h3>Data Pos Hidrologi.</h3>
                          <p>
                            Data Pos hidrologi Duga Air diinput oleh pengamat
                            pos dilapangan pada waktu-waktu tertentu.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="business-dashboard company-dashboard ecommerce-dashboard-v1"
                  >
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="dashboard-card is-base-chart">
                          <div class="content-box">
                            <div class="revenue-stats is-dark-bordered-12">
                              Tinggi Muka air (cm)
                            </div>
                          </div>
                          <div class="chart-container">
                            <v-chart autoresize class="chart" :option="awlr" />
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <!-- Datatable -->
                        <div class="table-wrapper">
                          <b-table
                            class="table is-datatable is-hoverable table-is-bordered"
                            :data="hidrologiPda"
                            :columns="columnsHidroPda"
                            focusable
                            paginated
                            backend-pagination
                            :total="totalHidroPda"
                            :per-page="perPage"
                            @page-change="onPageHidroPdaChange"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page"
                            backend-sorting
                            :default-sort-direction="defaultSortOrder"
                            :default-sort="[sortField, sortOrder]"
                            @sort="onSort"
                            icon-pack="fas"
                            icon-left="chevron-right"
                          >
                            <template #empty>
                              <div class="has-text-centered">No records</div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END TAB Hidrologi PDA -->
                <div
                  class="tab-content"
                  v-bind:class="{ 'is-active': isActive == 'HidrologiPch' }"
                  v-if="kadaster.isManualPch"
                >
                  <div class="datatable-toolbar">
                    <div class="buttons">
                      <SearchHidrologiPch :registerId="registerId" />
                    </div>
                  </div>
                  <div class="lifestyle-dashboard lifestyle-dashboard-v4">
                    <div class="columns">
                      <div class="column is-12">
                        <div class="columns is-multiline">
                          <!--Header-->
                          <div class="column is-12">
                            <div class="illustration-header-2">
                              <div class="header-image">
                                <img
                                  src="../../assets/img/illustrations/placeholders/search-1.svg"
                                  alt=""
                                  width="200"
                                />
                              </div>
                              <div class="header-meta">
                                <h3>Data Pos Hidrologi.</h3>
                                <p>
                                  Data Pos hidrologi Curah Hujan diinput oleh
                                  pengamat pos dilapangan pada waktu-waktu
                                  tertentu.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="business-dashboard company-dashboard ecommerce-dashboard-v1"
                  >
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="dashboard-card is-base-chart">
                          <div class="content-box">
                            <div class="revenue-stats is-dark-bordered-12">
                              Pos Curah Hujan (mm)
                            </div>
                          </div>
                          <div class="chart-container">
                            <v-chart autoresize class="chart" :option="arr" />
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <!-- Datatable -->
                        <div class="table-wrapper">
                          <b-table
                            class="table is-datatable is-hoverable table-is-bordered"
                            :data="hidrologiPch"
                            :columns="columnsHidroPch"
                            focusable
                            paginated
                            backend-pagination
                            :total="totalHidroPch"
                            :per-page="perPage"
                            @page-change="onPageHidroPchChange"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page"
                            backend-sorting
                            :default-sort-direction="defaultSortOrder"
                            :default-sort="[sortField, sortOrder]"
                            @sort="onSort"
                            icon-pack="fas"
                            icon-left="chevron-right"
                          >
                            <b-table-column
                              field="tanggal"
                              label="Tanggal"
                              v-slot="props"
                            >
                              {{ props.row.tanggal }}
                            </b-table-column>

                            <b-table-column
                              field="value"
                              label="value (mm)"
                              v-slot="props"
                            >
                              {{ props.row.value }}
                            </b-table-column>

                            <template #empty>
                              <div class="has-text-centered">No records</div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END TAB Hidrologi PCH -->
                <div
                  class="tab-content"
                  v-bind:class="{ 'is-active': isActive == 'Debit' }"
                >
                  <div class="lifestyle-dashboard lifestyle-dashboard-v4">
                    <div class="columns">
                      <div class="column is-12">
                        <div class="columns is-multiline">
                          <!--Header-->
                          <div class="column is-12">
                            <div class="illustration-header-2">
                              <div class="header-image">
                                <img
                                  src="../../assets/img/illustrations/placeholders/chart-guy-dark.svg"
                                  alt=""
                                />
                              </div>
                              <div class="header-meta">
                                <h3>Data Debit.</h3>
                                <p>
                                  Data Debit Air Satuan m3 (meter kubik) hasil
                                  perhitungan telemetri tinggi muka air.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="business-dashboard company-dashboard ecommerce-dashboard-v1"
                  >
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <!-- Datatable -->
                        <div class="table-wrapper">
                          <b-table
                            class="table is-datatable is-hoverable table-is-bordered"
                            :data="debit"
                            :columns="columnsDebit"
                            focusable
                            paginated
                            backend-pagination
                            :total="totalDebit"
                            :per-page="perPage"
                            @page-change="onPageDebitChange"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="PageDebit"
                            aria-current-label="Current page"
                            backend-sorting
                            :default-sort-direction="defaultSortOrder"
                            :default-sort="[sortField, sortOrder]"
                            @sort="onSort"
                            icon-pack="fas"
                            icon-left="chevron-right"
                          >
                            <b-table-column
                              field="timestamp"
                              label="Tanggal"
                              v-slot="props"
                            >
                              {{ props.row.timestamp }}
                            </b-table-column>

                            <b-table-column
                              field="debit"
                              label="Debit (m³)"
                              v-slot="props"
                            >
                              {{ props.row.debit }}
                            </b-table-column>

                            <template #empty>
                              <div class="has-text-centered">No records</div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END TAB Debit -->
              </div>
            </div>

            <!-- END OF Lifestyle dashboard -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";

import LevelList from "../level/LevelList.vue";
import RumusDebit from "../debit/RumusDebit.vue";
import Tablebatch from "../batchhidrologi/Tablebatch.vue";
import TableEvaporation from "../evaporasiklim/TableEvaporation.vue";

import SearchHidrologiPda from "./SearchHidrologiPda.vue";
import SearchHidrologiPch from "./SearchHidrologiPch.vue";

// TABLE

import TablePdaTelemetry from "./TablePdaTelemetry.vue";
import TablePchTelemetry from "./TablePchTelemetry.vue";
import TableKlimatologi from "./TableKlimatologi";

export default {
  name: "PosDetail",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    LevelList,
    RumusDebit,
    SearchHidrologiPda,
    SearchHidrologiPch,
    TablePdaTelemetry,
    TablePchTelemetry,
    TableKlimatologi,
    Tablebatch,
    TableEvaporation,
  },
  data() {
    return {
      isActive: "Detail Pos",
      isLoaded: false,
      kodeRegister: "",
      registerId: "",
      // charts
      waspada: 5,
      siaga: 50,
      awas: 60,
      tinggiTanggul: 150,
      // data table
      hidrologiPda: [],
      hidrologiPch: [],
      debit: [],
      totalHidroPda: 0,
      totalHidroPch: 0,
      totalDebit: 0,
      loading: false,
      sortField: "tanggal",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      pageHidroPda: 0,
      pageHidroPch: 0,
      pageDebit: 0,
      perPage: 10,

      columnsHidroPda: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "tma",
          label: "TMA (Cm)",
        },
      ],
      columnsHidroPch: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "value",
          label: "value (mm)",
        },
      ],
      columnsDebit: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "debit",
          label: "Debit (m³)",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      awlr: {},
      arr: {},
      // end data table
    };
  },
  created() {
    this.registerId = this.$route.params.registerId;
    this.getSungaiFromKadaster(this.registerId);
    this.fetchKadasterById(this.registerId).then((resp) => {
      this.kodeRegister = resp.kodeRegister;
      const gambar = resp.gambar;
      if (gambar) {
        this.getGambar(this.registerId);
      }
    });

    this.isLoaded = true;
    this.fetchLevelByRegisterId(this.registerId).then((resp) => {
      this.waspada = resp.waspada;
      this.siaga = resp.siaga;
      this.awas = resp.awas;
      this.tinggiTanggul = resp.tinggiTanggul;
    });

    this.fetchEvaporasiByRegisterId(this.registerId).then((resp) => {
      this.volume = resp.volume;
    });

    this.fetchPdaHidrologiKodeRegister(this.registerId).then((resp) => {
      if (resp != null) {
        this.hidrologiPda = [];
        let currentTotal = resp.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.totalHidroPda = currentTotal;

        resp.forEach((item) => {
          this.loading = false;
          this.hidrologiPda.push(item);
        });
        this.awlr = {
          title: {
            left: "1%",
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "5%",
            right: "15%",
            bottom: "10%",
            top: "10%",
          },
          xAxis: {
            data: resp.map((r) => r.tanggal).reverse(),
          },
          yAxis: {
            axisLabel: {
              formatter: "{value} cm",
            },
          },

          dataZoom: [
            {
              startValue: "1970-01-01",
            },
            {
              type: "inside",
            },
          ],
          series: {
            name: "TMA",
            type: "line",
            data: resp.map((r) => r.tma).reverse(),
          },
        };
      }
    });

    this.fetchPchHidrologiKodeRegister(this.registerId).then((resp) => {
      if (resp != null) {
        this.hidrologiPch = [];
        let currentTotal = resp.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.totalHidroPch = currentTotal;

        resp.forEach((item) => {
          this.loading = false;
          this.hidrologiPch.push(item);
        });

        const colors = ["#5470C6", "#91CC75", "#EE6666"];
        this.arr = {
          color: colors,
          title: {
            left: "1%",
          },
          legend: {},
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          dataZoom: [
            {
              type: "inside",
            },
            {
              type: "slider",
            },
          ],
          xAxis: {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            data: resp.map((r) => r.tanggal).reverse(),
          },
          yAxis: {
            type: "value",
            name: "Volume",
            position: "right",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0],
              },
            },
            axisLabel: {
              formatter: "{value} mm",
            },
          },
          series: [
            {
              data: resp.map((r) => r.value).reverse(),
              type: "bar",
            },
          ],
        };
      }
    });
  },
  computed: {
    ...mapState({
      kadaster: (state) => state.kadasters.item,
      sungai: (state) => state.sungai.item,
      image: (state) => state.image.item,
      level: (state) => state.level.item,
      kanalPch: (state) => state.kanalPch.items,
      hidrologi: (state) => state.hidrologi.items,
      hidrologiCh: (state) => state.hidrologiCh.items,
      debits: (state) => state.debit.items,
      klimatologi: (state) => state.klimatologi.items,
      evaporasi: (state) => state.evaporasi.item,
    }),
    getImgUrl() {
      var images = this.image;
      return images;
    },
  },
  destroyed() {
    // this.$socket.removeListener("connect", this.addChannelToChart);
  },

  methods: {
    ...mapActions("kadasters", ["fetchKadasterById"]),
    ...mapActions("sungai", ["getSungaiFromKadaster"]),
    ...mapActions("image", ["getGambar"]),
    ...mapActions("level", ["fetchLevelByRegisterId"]),
    ...mapActions("kanalPch", ["fetchPch", "addPch"]),
    ...mapActions("hidrologi", ["fetchPdaHidrologiKodeRegister"]),
    ...mapActions("hidrologiCh", ["fetchPchHidrologiKodeRegister"]),
    ...mapActions("debit", ["fetchDebitRegisterId"]),
    ...mapActions("klimatologi", ["fetchDataKlimDash"]),
    ...mapActions("evaporasi", ["fetchEvaporasiByRegisterId"]),
    setSelected(tab) {
      this.selected = tab;
    },

    onImageLoadFailure(event) {
      event.target.src = require("../../assets/image1.png");
    },

    // channnel table
    loadAsyncData() {
      this.loading = true;

      this.fetchDebitRegisterId(this.registerId).then(() => {
        this.debit = [];
        let currentTotal = this.debits.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.totalDebit = currentTotal;
        this.debits.forEach((item) => {
          this.isLoaded = false;
          item.tanggal = moment(String(item.tanggal)).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.debit.push(item);
        });
      });

      //end Load Async Data
    },

    onPageHidroPdaChange(page) {
      this.pageHidroPda = page;
      this.loadAsyncData();
    },
    onPageHidroPchChange(page) {
      this.pageHidroPch = page;
      this.loadAsyncData();
    },
    onPageDebitChange(page) {
      this.pageDebit = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },

    cariChannel() {
      // console.log(this.form)
      this.$router.push(`/pencarian/${this.registerId}`);
    },
    // end channel
    deletKadaster() {
      this.$swal({
        title: "Apakah anda yakin akan menghapus Data Pos tersebut?!",
        text: "Mohon pastikan kembali!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, HAPUS!",
        cancelButtonText: "Batal!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$router.push("/dashboard/pos");
          this.$store.dispatch("kadasters/deleteKadasterById", this.registerId);
          this.$toasted.success("Hapus Data Kadaster Berhasil", {
            duration: 5000,
          });
          this.$emit("kadasterRemoveSubmitted", this.registerId);
        }
      });
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>
<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

@media (max-width: 868px) {
  .chart {
    height: 200px;
    width: 360px;
  }
}
.tombol {
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
